import React, { useState, useEffect, useRef } from "react";
import "../sagos/sagosJoinStyle.scss";
import { Modal, Row, Col, ListGroup, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { TimelineLite, Power3 } from "gsap";
import { useDispatch, useSelector } from "react-redux";
import { userLanguage } from "../../actions/actions";

export default function MembersContent2({ fullpage, animation }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let tl = new TimelineLite({ delay: 0.8 });

  let textSection = useRef();
  let image1 = useRef();
  let image2 = useRef();

  const dispatch = useDispatch();
  let currentLanguage = useSelector((state) => state.language);

  const modalRight = useRef();

  let textBorder = useRef();
  let languageSection = useRef();
  let scrollSection = useRef();

  let modal1Ref = useRef();

  const [modal1, setModal1] = useState(false);

  useEffect(() => {
    if (modal1) {
      modal1Ref.click();
    }
  }, [modal1]);

  useEffect(() => {
    if (animation) {
      tl.from(image1, 1.5, {
        x: -50,
        opacity: 0,
        ease: Power3.easeOut,
      })
        .from(image2, 1.5, { x: 50, opacity: 0, ease: Power3.easeOut }, 0.2)
        .from(
          textSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.6
        )
        .from(textBorder, 1.5, { opacity: 0, ease: Power3.easeOut }, 0.6)
        .from(
          languageSection,
          1.5,
          { x: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        )
        .from(
          scrollSection,
          1.5,
          { y: 50, opacity: 0, ease: Power3.easeOut },
          0.8
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  return (
    <>
      <div>
        <Modal
          onExited={() => {
            fullpage.reBuild();
          }}
          show={show}
          onHide={() => {
            handleClose();
            setModal1(false);
            fullpage.setAutoScrolling(true);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <FontAwesomeIcon
            className="modalCloseButton"
            icon={faTimes}
            onClick={() => {
              handleClose();
              setModal1(false);
              fullpage.setAutoScrolling(true);
            }}
          ></FontAwesomeIcon>
          <Modal.Header className="modalHeader">
            <Modal.Title>
              <h2>Statuten &#38; Beitritt</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="list-group-tabs-example"
              defaultActiveKey="#infos"
            >
              <Row className="modalRow">
                <Col md={4}>
                  <ListGroup className="modalLeft">
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#infos"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                      ref={(el) => (modal1Ref = el)}
                    >
                      1. Infos
                    </ListGroup.Item>
                    <ListGroup.Item
                      className="modalLeftItem"
                      action
                      href="#beitreten"
                      onClick={() => {
                        modalRight.current.scrollTop -= 10000;
                      }}
                    >
                      2. Beitreten
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={8}>
                  <Tab.Content className="modalRight" ref={modalRight}>
                    <Tab.Pane className="modalRightTab" eventKey="#infos">
                      <h6>Die Statuten von SAGOS</h6>
                      <div>
                        <p>
                          <span>I. Name und Sitz des Vereins</span> <br></br>
                          <br></br>
                          Art. 1 Die schweizerische Ärztegesellschaft für Ozon-
                          und Sauerstoff-Anwendung in Therapie und Prävention
                          ist ein Verein im Sinne von Art. 60 – FS des
                          schweiz.Zivilgesetzbuches. Sie ist ein gemeinnütziger
                          Verein. <br></br>
                          <br></br> Art. 2 Der Sitz des Vereins ist am Wohnort
                          des Präsidenten. <br></br>
                          <br></br> Art. 3 Das Vereinsjahr ist das Kalenderjahr.{" "}
                          <br></br>
                          <br></br>
                          <span>II. Zweck und Zielsetzung des Vereins </span>
                          <br></br>
                          <br></br> Art. 1 Zweck und Zielsetzung des Vereins ist
                          es, unter Anwendung moderner technischer Mittel
                          präventivmedizinische und therapeutische Erfahrungen
                          mit der Ozon und andern Sauerstoffanwendungen auf
                          breiter Basis zu sammeln und der empirischen und
                          wissenschaftlichen Forschung zuzuführen. Der Verein
                          ist bestrebt, mit Hochschulen, Instituten, Kliniken,
                          Ärzten und fachlich qualifizierten Personen(z.B.
                          Biochemiker, Physiker, Techniker etc.), die sich mit
                          der Ozon-Sauerstoff-Forschung und/oder Anwendung
                          befassen, zusammenzuarbeiten. Bei
                          entsprechenVoraussetzungen kann die Zusammenarbeit
                          auch mit Firmen oder Industrieunternehmen erfolgen.{" "}
                          <br></br>
                          <br></br>
                          Art. 2 Förderung und Inangriffnahme aller dienlichen
                          Massnahmen wie Ausbildung und Fortbildung von Ärzten,
                          Zahnärzten und Tierärzten in Form von Kursen,
                          Seminaren, Kolloquien, Arbeitskreisen, Tagungen usw.{" "}
                          <br></br>
                          <br></br>
                          Art. 3 Beratung der Mitglieder in fachlichen und
                          standespolitischen Fragen. <br></br>
                          <br></br> Art. 4 Kontaktpflege mit ähnlichen
                          ärztlichen Vereinigungen des Auslands. <br></br>
                          <br></br>{" "}
                          <span>
                            III. Mitgliedschaft und Organe der Gesellschaft
                          </span>{" "}
                          <br></br>
                          <br></br> Art. 1 Die Gesellschaft setzt sich zusammen
                          aus ordentlichen, ausserordentlichen, fördern den und
                          Ehrenmitgliedern. <br></br>
                          <br></br> Art. 2 Ordentliche Mitglieder können alle in
                          der Schweiz tätigen, approbierten Ärzte, Zahn ärzte
                          und Tierärzte werden. <br></br>
                          <br></br> Art. 3 Ausserordentliche Mitglieder können
                          cand med sowie Personen mit abgeschlossener
                          akademischer Ausbildung werden, die den Zweck und die
                          Interessen des Vereins zu fördern gewillt sind, sofern
                          sie nicht selbst die Heilkunde ausüben. Sie haben
                          beratende Stimme. <br></br>
                          <br></br> Art. 4 Fördernde Mitglieder können als
                          Einzelpersonen oder Institutionen der Gesellschaft
                          beitreten, sofern sie den Zweck und die Statuten des
                          Vereins anerkennen und den Verein fördern wollen. Sie
                          haben beratende Stimme. <br></br>
                          <br></br> Art. 5 Die Mitgliedschaft wird erworben
                          durch Beitrittserklärung, die gegenüber dem Vorstand
                          abzugeben ist. Das Mitglied verpflichtet sich, den
                          Jahresbeitrag zu bezahlen (Beitritts- und
                          Austrittsjahr gelten als beitragspflichtige Jahre).
                          Lehnt der Vorstand die Aufnahme ab, so hat er dies dem
                          betreffenden mit oder ohne Begründung unverzüglich,
                          spätestens jedoch innerhalb vier Wochen schriftlich
                          mitzuteilen. <br></br>
                          <br></br> Art.6 Die Verleihung der
                          Ehrenmitgliedschaften an besonders verdiente
                          Mitglieder oder sonstige Persönlichkeiten ist auf
                          Vorschlag des Vorstandes durch Mehrheitsbe-Schluss der
                          Mitgliederversammlung möglich. Die Ehrenmitgliedschaft
                          ist nicht zwingend mit der Mitgliedschaft bei einer
                          Dachorganisation verbunden. Ehrenmit-glieder zahlen
                          keine Mitgliederbeiträge. <br></br>
                          <br></br> Art. 7 In schwerwiegenden Fällen kann der
                          Ausschluss eines Mitgliedes auf Vorschlag des
                          Vorstandes durch die Mitgliederversammlung erfolgen.
                          Eine Zweidrittelsmehrheit der Anwesenden ist dazu
                          erforderlich. <br></br>
                          <br></br> Art. 8 Die Organe des Vereins sind: a) der
                          Vorstand,b) die Mitgliederversammlung <br></br>
                          <br></br> Art. 9 Der Vorstand besteht aus drei bis
                          fünf Mitgliedern <br></br>
                          <br></br> a) dem Präsidenten <br></br> b) dem
                          Pastpräsidenten (beratende Funktion) <br></br> c) dem
                          Vizepräsidenten <br></br> d) dem Kassier <br></br> e)
                          dem Beisitzer bzw. den Beisitzern <br></br>
                          <br></br>Bei der Beschlussfassung im Vorstand
                          entscheidet die Stimmenmehrheit. Bei Stimmengleichheit
                          steht dem Präsidenten der Stichentscheid zu. <br></br>
                          <br></br> Die Aufgaben des Vorstandes sind: <br></br>{" "}
                          - Einberufung und Leitung der Hauptversammlung{" "}
                          <br></br> - Organisation von Seminaren, Kursen,
                          Arbeitskreisen, Tagungen etc. <br></br> - Erarbeitung
                          von Ausbildung- und Weiterbildungskriterien <br></br>-
                          Erarbeitung von Ausbildung- und
                          Weiterbildungskriterien <br></br> - Verleihung und
                          Anerkennung von Zertifikaten sowie Verlängerung von
                          bestehenden Zertifikaten im Sinne einer
                          Rezertifizierung (Qualitätssicherung). <br></br> -
                          Prüfung von Studienobjekten und deren Förderung -
                          Pflege der Beziehungen zu andern Ärzteorganisationen,
                          Institutionen, Behörden, Firmen, Medien etc. <br></br>{" "}
                          - Rechnungsführung, Berichterstattung, Führung der
                          Mitgliederliste. <br></br>
                          <br></br> Der Vorstand arbeitet ehrenamtlich, doch
                          können aussergewöhnliche Spesen im Rahmen des Budget
                          vergütet werden. <br></br>
                          <br></br>
                          Den Vorstandsmitgliedern steht eine durch die
                          Generalversammlung zu bestimmende fixe Entschädigung
                          zu. Weitere anfallende Spesen im Rahmen der operativen
                          Aufgaben können gemäss des der Generalversammlung
                          vorzulegenden Budget bewilligt werden.
                          <br></br>
                          <br></br>
                          Der Vorstand wird alle vier Jahre durch die
                          Generalversammlung gewählt. Seine Mitglieder sind
                          wiederwählbar. <br></br>
                          <br></br>
                          Art. 10 Die reguläre Mitgliederversammlung findet
                          einmal jährlich statt. Bei allen Sach-Abstimmungen
                          entscheidet das einfache Mehr der anwesenden
                          Stimmberechtigten. Die Mitgliederversammlung muss
                          mindestens dreissig Tage vorher bekannt gegeben
                          werden. Ausser den vorgesehenen Traktanden der
                          Generalversammlung können Anträge betreffend weiteren
                          Traktanden eingereicht werden. Sie müssen mindestens
                          zehn Tage vor der Generalversammlung allen
                          stimmberechtigten Mitgliedern mitgeteilt werden.{" "}
                          <br></br>
                          <br></br>
                          Art. 11 Dringliche Abstimmungen über Einzelgeschäfte
                          können auf dem Briefweg erfolgen. <br></br>
                          <br></br> <span>IV. Varia</span> <br></br>
                          <br></br> Art. 1 Die Mitgliederbeiträge werden
                          jährlich auf Antrag des Kassiers durch die
                          Generalversammlung festgelegt. <br></br>
                          <br></br> Vertretung: Der Verein wird vertreten durch
                          den Präsidenten und ein zweites Vorstandsmitglied.{" "}
                          <br></br>
                          <br></br>
                          Art. 2 Auflösung: die Auflösung der Gesellschaft kann
                          durch Zweidrittelmehrheit aller stimmberechtigter
                          Mitglieder in einer Urabstimmung beschlossen werden.{" "}
                          <br></br>
                          <br></br>
                          Über das Vereinsvermögen entscheidet die
                          Generalversammlung mit einfachem Mehr. <br></br>
                          <br></br> Diese Statuten ersetzen die
                          Gründungsstatuten vom 13.2.1986 und treten nach
                          Genehmigung durch die Hauptversammlung vom 18.11.04 am
                          1.1.05 in Kraft.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane className="modalRightTab" eventKey="#beitreten">
                      <h6>Beitrittserklärung</h6>
                      <div>
                        <p>
                          Füllen Sie die folgende Beitrittserklärung aus und
                          schicken Sie diese uns per Email zu: <br></br>
                          <span>kontakt@sagos.info</span>
                          <br></br>
                          <br></br>
                          Download:
                          <br></br>
                          <FontAwesomeIcon
                            className="pdfIcon"
                            icon={faFilePdf}
                          ></FontAwesomeIcon>
                          <a
                            href="https://firebasestorage.googleapis.com/v0/b/sagosweb-e50b5.appspot.com/o/Beitrittserkla%CC%88rung%20SAGOS%20%20version%202023.pdf?alt=media&token=a1494632-3cf8-48c7-8a22-6dcb15c2cc19"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Beitrittserklärung.pdf
                          </a>
                        </p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
      <div className="languageSelector" ref={(el) => (languageSection = el)}>
        <h1
          className={
            currentLanguage === "deutsch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("deutsch"));
          }}
        >
          DE
        </h1>
        <h1
          className={
            currentLanguage === "französisch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("französisch"));
          }}
        >
          FR
        </h1>
        <h1
          className={
            currentLanguage === "italienisch"
              ? "activeLanguage"
              : "inactiveLanguage"
          }
          onClick={() => {
            dispatch(userLanguage("italienisch"));
          }}
        >
          IT
        </h1>
      </div>
      <div
        className="navigationText"
        ref={(el) => (scrollSection = el)}
        onClick={() => {
          fullpage.moveSectionDown();
        }}
      >
        <h1>
          Nächstes Thema: <br></br>Forschung und Experimente zur Ozontherapie
        </h1>
        <div className="imageWrapper">
          <img
            src={process.env.PUBLIC_URL + "images/background/arrowDown.svg"}
            alt="scroll down icon"
          ></img>
        </div>
      </div>
      <div className="row justify-content-center align-self-center membersRow2">
        <div className="textBorderLeft" ref={(el) => (textBorder = el)}></div>
        <div className="col-md-4 rightSide" ref={(el) => (textSection = el)}>
          <h1>
            Wie kann ich SAGOS beitreten <br></br> und
            <span> Mitglied werden?</span>
          </h1>
          <p>
            Sie sind daran interessiert, uns beizutreten und Mitglied von SAGOS
            zu werden? Lesen Sie unsere Mitgliedsbedingungen durch und in
            wenigen Schritten können Sie bald ein Mitglied von SAGOS werden.
          </p>
          <div className="buttonCenter">
            <button
              onClick={() => {
                handleShow();
                setModal1(true);
                fullpage.setAutoScrolling(false);
              }}
            >
              Mehr zum Thema
            </button>
          </div>
        </div>
        <div className="col-md-8">
          <img
            className="sagosJoin3-1 imageHide"
            ref={(el) => (image1 = el)}
            src={process.env.PUBLIC_URL + "images/background/sagos3.1.svg"}
            alt="doctors"
          ></img>
          <img
            className="sagosJoin3-2 imageHide"
            ref={(el) => (image2 = el)}
            src={process.env.PUBLIC_URL + "images/background/sagos3.2.svg"}
            alt="doctors"
          ></img>
        </div>
      </div>
    </>
  );
}
