import React from "react";

export const News = (props) => {
  return (
    <div>
      <p>
        <span>Daten der nächsten Ozontherapie-Seminare in der Schweiz:</span>
        <br></br>
        <br></br>
        Fortbildung in Zürich: <br /> <span>22.06.2024</span> <br />
          <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/sagosweb-e50b5.appspot.com/o/1.%20SAGOS%20Einladung%20Juni%202024%20%20%5B38433%5D.pdf?alt=media&token=e6e34420-aa87-4012-a859-e09e9926f99f">Anmeldung herunterladen</a>
          <br></br>
          <br></br>
        Fortbildung in Basel: <br /> <span>30.11.2024</span> <br />
      </p>

      <br></br>
      <br></br>
      <p></p>
    </div>
  );
};
